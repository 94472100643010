import React from 'react'
import { withPrefix } from 'gatsby'
import { Router } from '@reach/router'
import RestaurantsSection from '../components/RestaurantsSection'
import RestaurantPage from '../components/RestaurantProfile'

export default () => (
  <Router>
    <RestaurantsSection withImage={true} path={withPrefix('/restaurants')} />
    <RestaurantPage path={withPrefix('/restaurants/:slug')}/>
  </Router>
)
