import React from 'react'
import './DownloadApp.css'
import Head from './Head'
import Button from './Button'

const ScreenWeb = require('../assets/images/pages/download_web.jpg')
const ScreenMobile = require('../assets/images/pages/download_mobile.jpeg')
const ImgIos = require('../assets/images/pages/ios.png')
const ImgAndroid = require('../assets/images/pages/android.png')
const FrameTop = require('../assets/images/pages/frame-top.png')
const whatsappIcon = require('../assets/images/general/whatsapp-alt.svg')


const DownloadApp = () => {
    return (<>
      <Head
        title="Club Gourmet - Grande Table"
        description="Sé parte del Club Gourmet más exclusivo del país."
        url="https://www.grandetable.com.ec/"
        image="https://www.grandetable.com.ec/featured-image.jpg"
      />
      <div className="w-full h-screen figtree">
        {/* Mobile view */}
        <div className='d-flex d-md-none dw-container justify-content-between' style={{ backgroundImage: `url("${ScreenMobile}")` }}>
          <div>
            <div className='text-center'>
              <img src={FrameTop} className='' alt="Grande Table" />
            </div>
            <div>
              <p className='dw-title text-center my-5'>
                Gestiona tus <b className='figtree-bold'>reservas</b> <br />
                de forma <b className='figtree-bold'>sencilla</b> y cómoda <br />
                desde la <b className='figtree-bold'>App Grande Table</b>.
              </p>
              <div className="d-flex flex-column align-items-center">
                <a href="https://play.google.com/store/apps/details?id=ec.com.grandetableapp" className='mb-4'>
                  <img className="w-full" style={{maxWidth: 200}} src={ImgAndroid} alt="Google Play" />
                </a>
                <a href="https://apps.apple.com/ec/app/grande-table/id1672085176">
                  <img className="w-full" style={{maxWidth: 200}} src={ImgIos} alt="App Store" />
                </a>
              </div>
            </div>
          </div>

          <div style={{marginBottom: '4rem'}}>
            <p className='help-text text-center'>
              ¿Necesitas ayuda? Escríbenos.
            </p>

            <Button className='help-button' link='https://walink.co/f22c27'>
              <img src={whatsappIcon} className='mr-1'/>
              <span>Whatsapp</span>
            </Button>
          </div>
        </div>
        {/* Web view */}
        <div className='d-none d-md-flex dw-container dw-container-desk justify-content-between' style={{ backgroundImage: `url("${ScreenWeb}")` }}>
          <div>
            <img src={FrameTop} alt="Grande Table" />
          </div>
          <div>
            <p className='dw-title mb-4'>
              Gestiona tus <b className='figtree-bold'>reservas</b> <br />
              de forma <b className='figtree-bold'>sencilla</b> y cómoda <br />
              desde la <b className='figtree-bold'>App Grande Table</b>.
            </p>
            <div className="d-flex mb-">
              <a style={{maxWidth: 200}} href="https://play.google.com/store/apps/details?id=ec.com.grandetableapp" className='mr-4'>
                <img className="w-full" src={ImgAndroid} alt="Google Play" />
              </a>
              <a style={{maxWidth: 200}} href="https://apps.apple.com/ec/app/grande-table/id1672085176">
                <img className="w-full" src={ImgIos} alt="App Store" />
              </a>
            </div>

            <div className='mt-5'>
              <p className='help-text'>
               ¿Necesitas ayuda? Escríbenos.
              </p>

              <Button className='help-button' link='https://walink.co/f22c27'>
                <img src={whatsappIcon} className='mr-1'/>
                <span>Whatsapp</span>
              </Button>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </>)
}


export default DownloadApp;