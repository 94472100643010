import React, { Component } from 'react'
import Modal from 'react-modal'
import withLocation from '../AuthSection/withLocation'
import DownloadApp from '../DownloadApp'

Modal.setAppElement('#___gatsby')

class RestaurantsSection extends Component<{ callback?: Function; withImage?: boolean; noId?: number | null; search: object }, any> {
  render() {

    return (
      <div>
        <DownloadApp/>
      </div>
    )
  }
}

export default withLocation(RestaurantsSection)
